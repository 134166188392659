import React, { useState, useEffect } from 'react';
import { Row, Alert, Col, Button, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { site_ip } from '../globalSetting';
// import Header from '../Header';
import { useNavigate, useParams } from 'react-router-dom';
import tripLogo from '../image/Trip_logo_Grey.png';
import kn from '../image/knowladgebridge.png';
const ForgotPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [email, setEmail] = useState("");
  
  const submitEmail = async () => {

    axios({
      method: 'post',
      url: site_ip + '/auth/forgotPassword',
      data: {
        user_email: email?.trim()
      },
      dataType: 'json',
    })
      .then(function (response) {
        if (response.data.Status === 200) {
          alert(
            'Thank you for resetting the password, pls check your email for details'
          );
          window.location.href = '/';
        } else alert('Seems, this email is not registered with us.');
      })
      .catch(function (error) {
        console.log('error1' + error);
      });

  }

  return (
    <div>
      <Row style={{margin:"0px"}}>
        <Col style={{ paddingRight: '0px' }}>
          <div className="sponser_logo">
            {/* <img
                  src={torrent}
                  alt="sponser logo"
                  className="sponser_logo"
                /> */}
            {/* <img
                  className="trip_up_to "
                  alt="Responsive image"
                  src={tripLogo}
                /> */}
          </div>
        </Col>
        <Col style={{ paddingLeft: '0px' }}>
          <div className="right_pic_collection_up">
            <div
              className="text-right "
              style={{
                marginBottom: '-20px',
                marginRight: '20px',
                fontSize: '0.8em',
              }}
            >
              Partnering with
            </div>
            <img
              className="trip_up_to "
              alt="Responsive image"
              src={tripLogo}
            />
            <span className="vr"></span>
            <img
              className="knowladgebridge_pic "
              alt="Responsive image"
              src={kn}
            />
          </div>
        </Col>
      </Row>
      <Row style={{margin:"0px"}}>
        <Col></Col>
        <Col>
          <div className='reset-box'>
            <div className="first-half" style={{height:"100px"}}>
              <div className='header-section'>
                <p className='logo-text'>FORGOT PASSWORD</p>
              </div>
            </div>
            <div className='login-box-form'>
              <p className='label'>{"Enter your registered email"}</p>
              <input
                type={"text"}
                className='form-control'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: "30px" }}>
                <Button onClick={() => submitEmail()}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}

export default ForgotPassword;


