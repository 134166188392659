import React, { useState, useEffect } from 'react';
import { Row, Alert, Col, Button, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { site_ip } from '../globalSetting';
import Header from '../Header';
import { useNavigate, useParams } from 'react-router-dom';
import tripLogo from '../image/Trip_logo_Grey.png';
import kn from '../image/knowladgebridge.png';
const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setError(null)
  }, [password, confirmPassword])

  const updatePassword = async () => {

    if (password?.trim().length < 8) {
      setError("Password must be minimum 8 character long.")
      return
    } else if (password !== confirmPassword) {
      setError("Password not matched.")
      return
    }

    axios({
      method: 'post',
      url: site_ip + '/auth/updateUserPass',
      data: {
        passw_link: params?.id,
        password: password?.trim()
      },
      dataType: 'json',
    })
      .then(function (response) {
        setSuccess(true)
        setTimeout(() => {
          navigate("/")
        }, 2000)
      })
      .catch(function (error) {
        console.log('error1' + error);
      });

  }

  return (
    <div>
      <Row style={{margin:"0px"}}>
        <Col style={{ paddingRight: '0px' }}>
          <div className="sponser_logo">
            {/* <img
                  src={torrent}
                  alt="sponser logo"
                  className="sponser_logo"
                /> */}
            {/* <img
                  className="trip_up_to "
                  alt="Responsive image"
                  src={tripLogo}
                /> */}
          </div>
        </Col>
        <Col style={{ paddingLeft: '0px' }}>
          <div className="right_pic_collection_up">
            <div
              className="text-right "
              style={{
                marginBottom: '-20px',
                marginRight: '20px',
                fontSize: '0.8em',
              }}
            >
              Partnering with
            </div>
            <img
              className="trip_up_to "
              alt="Responsive image"
              src={tripLogo}
            />
            <span className="vr"></span>
            <img
              className="knowladgebridge_pic "
              alt="Responsive image"
              src={kn}
            />
          </div>
        </Col>
      </Row>
      <Row style={{margin:"0px"}}>
        <Col></Col>
        <Col>
          <div className='reset-box'>
            <div className="first-half">
              <div className='header-section'>
                <p className='logo-text'>RESET PASSWORD</p>
              </div>
            </div>
            <div className='login-box-form'>
              {success ?
                <Alert variant="success">
                  <span><b>Password updated successfully!</b></span>
                </Alert> : null}
              <p className='label'>{"New Password"}</p>
              <input
                type={"password"}
                className='form-control'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <p className='label'>{"Confirm Password"}</p>
              <input
                type={"password"}
                className='form-control'
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
              <div>
                {error !== null ? <p className='form-error'>{error}</p> : null}
              </div>
              <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: "30px" }}>
                <Button onClick={() => updatePassword()}>
                  Update
                </Button>
              </div>

              {/* <button className="primary-button m-tb-3" onClick={() => updatePassword()}>
                              <div>
                                  <p>{"Update"}</p>
                              </div>
                          </button> */}
            </div>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}

export default ResetPassword;


