import React, { Component } from 'react';
import { Row, Container, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../globalSetting';
import OtpInput from 'react-otp-input';
import tripLogo from '../image/trip_up.png';
import tripUP2 from '../image/trip_b_pic.jpg';
import tripUP from '../image/trip_b_mobile.png';

import kn from '../image/knowladgebridge.png';

export default class OTPLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile_no: '',
      otp: '',
      numInputs: 6,
      separator: '-',
      isDisabled: false,
      hasErrored: false,
      isInputNum: false,
      isInputSecure: false,
      minLength: 0,
      maxLength: 40,
      placeholder: '',
      mobileotp: '',
      otpDisable: true,
      otpLogin: true,
      errorMsg: '',
      errorMobile: '',
    };
    this.onChangeMobile = this.onChangeMobile.bind(this);
    this.handleMobileOtp = this.handleMobileOtp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOtp = this.handleOtp.bind(this);
  }
  handleChange = (otp) => this.setState({ otp });
  onChangeuserEmail(e) {
    this.setState({
      user_email: e.target.value,
    });
  }
  onChangeMobile(e) {
    this.setState({
      mobile_no: e.target.value,
      errorMobile: '',
    });
  }
  handleOtpChange = (otp) => {
    this.setState({ otp });
    this.setState({ errorMsg: '' });
  };

  handleOtp() {
    this.setState({ otpDisable: false });
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleNumInputsChange = (e) => {
    let numInputs = e.target.value;
    const { minLength, maxLength } = this.state;

    if (numInputs < minLength || numInputs > maxLength) {
      numInputs = 6;

      console.error(
        `Please enter a value between ${minLength} and ${maxLength}`
      );
    }

    this.setState({ [e.target.name]: parseInt(numInputs, 10) });
  };

  clearOtp = () => {
    this.setState({ otp: '' });
  };

  handleCheck = (e) => {
    const { name } = e.target;
    this.setState((prevState) => ({ [name]: !prevState[name] }));
  };

  handleSubmit(e) {
    e.preventDefault();
    let objectThis = this;
    let userotp = objectThis.state.mobileotp;
    let otp = objectThis.state.otp;
    if (Number(otp) !== Number(userotp)) {
      objectThis.setState({ errorMsg: 'Please enter correct OTP' });
    } else {
      objectThis.setState({ errorMsg: '' });
      axios
        .post(site_ip + '/auth/verifyOTP/' + objectThis.state.mobile_no)
        .then(function (response) {
          if (response.data) {
            objectThis.setState({ userdata: response.data.userdata });
            localStorage.setItem(
              'userdata',
              JSON.stringify(response.data.userdata)
            );
            window.location.href = '/Dashboard';
            objectThis.child.current.handleUserAnalytic();
          } else {
            objectThis.setState({ userdata: [] });
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    }
  }
  handleMobileOtp() {
    var thisObject = this;
    let mobile = thisObject.state.mobile_no;
    axios
      .post(site_ip + '/auth/sendOTP/' + mobile)
      .then(function (response) {
        if (response.data.Status === 200) {
          console.log('hello');
          thisObject.setState({ mobileotp: response.data.OTP });
          thisObject.setState({ otpDisable: false });
        } else if (
          response.data.Status === 400 ||
          response.data.Status === 404
        ) {
          console.log('hi');
          thisObject.setState({ errorMobile: response.data.Content });
          thisObject.setState({ otpDisable: true });
        } else {
          console.log('hi8u8unsa');
          thisObject.setState({ mobileotp: [] });
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  }
  render() {
    const {
      otp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
      isInputSecure,
      placeholder,
    } = this.state;
    return (
      <Container fluid className="cust-height h-100">
        <Row>
            <Col style={{ paddingRight: '0px' }}>
              <div className="sponser_logo">
                {/* <img
                  src={torrent}
                  alt="sponser logo"
                  className="sponser_logo"
                /> */}
              </div>
            </Col>
            <Col style={{ paddingLeft: '0px' }}>
              <div className="right_pic_collection_up">
                <div
                  className="text-right "
                  style={{
                    marginBottom: '-20px',
                    marginRight: '20px',
                    fontSize: '0.8em',
                  }}
                >
                  Partnering with
                </div>
                <img
                  className="trip_up_to "
                  alt="Responsive image"
                  src={tripLogo}
                />
                <span className="vr"></span>
                <img
                  className="knowladgebridge_pic "
                  alt="Responsive image"
                  src={kn}
                />
              </div>
            </Col>
          </Row>
        <Row className="justify-content-center h-100 align-items-lg-center align-items-end cust-height">
          <Col md={12} className="mr-auto" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <div className="border bg-white rounded-xl p-4 m-md-5 shadow">
              {this.state.otpDisable === true ? (
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <p className="top-head">
                      Enter your Mobile Number to get OTP
                    </p>
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="Enter Mobile Number"
                      name="user_email"
                      maxLength="10"
                      value={this.state.mobile_no}
                      onChange={this.onChangeMobile}
                    />
                    <span style={{ color: 'red' }}>
                      {this.state.errorMobile}
                    </span>
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-center mt-5 mb-5">
                    <Button
                      variant="danger btn-lg px-5 mx-auto"
                      onClick={this.handleMobileOtp}
                      style={{backgroundColor:"#64226d", border:"1px solid #64226d"}}
                    >
                      Get OTP
                    </Button>
                  </div>
                </Form>
              ) : (
                <div className="login-box mb-5">
                  <form>
                    <p className="top-head">
                      OTP is sent to your Mobile Number
                    </p>
                    <div className="margin-top--small">
                      <OtpInput
                        inputStyle="inputStyle"
                        numInputs={numInputs}
                        isDisabled={isDisabled}
                        hasErrored={hasErrored}
                        errorStyle="error"
                        onChange={this.handleOtpChange}
                        separator={<span>{separator}</span>}
                        isInputNum={isInputNum}
                        isInputSecure={isInputSecure}
                        shouldAutoFocus
                        value={otp}
                        placeholder={placeholder}
                      />
                      <span style={{ color: 'red' }}>
                        {this.state.errorMsg}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <button
                        className="btn btn-secondary px-4 mx-3 btn-lg"
                        type="button"
                        disabled={isDisabled || otp.trim() === ''}
                        onClick={this.clearOtp}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-danger px-4 mx-3 btn-lg"
                        disabled={otp.length < numInputs}
                        onClick={this.handleSubmit}
                        style={{backgroundColor:"#64226d", border:"1px solid #64226d"}}
                      >
                        Verify OTP
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
