import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './style.css';
import tripLogo from '../image/Trip_logo_Grey.png';

import kn from '../image/knowladgebridge.png';

const { detect } = require('detect-browser');
const browser = detect();
export default class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid className="h-100">
        <div>
          <Row>
            <Col style={{ paddingRight: '0px' }}>
              <div className="sponser_logo">
                {/* <img
                  src={torrent}
                  alt="sponser logo"
                  className="sponser_logo"
                /> */}
              </div>
            </Col>
            <Col style={{ paddingLeft: '0px' }}>
            <div className="right_pic_collection_up">
                <div
                  className="text-right "
                  style={{
                    marginBottom: '-20px',
                    marginRight: '20px',
                    fontSize: '0.8em',
                  }}
                >
                  Partnering with
                </div>
                <img
                  className="trip_up_to "
                  alt="Responsive image"
                  src={tripLogo}
                />
                <span className="vr"></span>
                <img
                  className="knowladgebridge_pic "
                  alt="Responsive image"
                  src={kn}
                />
              </div>
            </Col>
          </Row>
          <br />
          <div>
            <Row>
              <Col md={6}>
                  <video autoPlay={false} controls={true} style={{width:"100%"}}>
                    <source src="https://kb-web-of-discovery.s3.ap-south-1.amazonaws.com/An+introduction+of+the+Trip+Database.mp4" type="video/mp4" />
                  </video>
                  <p style={{fontSize:"18px", color:"#000000", marginTop:"20px", textAlign:"center", fontWeight:"bold"}}>An introduction of the Trip Database</p>
              </Col>
              <Col md={6}>
                  <video autoPlay={false} controls={true} style={{width:"100%"}}>
                    <source src="https://kb-web-of-discovery.s3.ap-south-1.amazonaws.com/How+to+access+Trip+Database.mp4" type="video/mp4" />
                  </video>
                  <p style={{fontSize:"18px", color:"#000000", marginTop:"20px", textAlign:"center", fontWeight:"bold"}}>How to access Trip Database</p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}

